import GLightbox from 'glightbox';

const SearchPopup = () => {
  const lightbox = GLightbox({
    'selector': '.search-popup-js',
    onOpen: function(){
      $('.gslider form input[type=text]').focus();
    },
  });
}

export default SearchPopup;
import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tooltip';
import './style.scss';
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import whichMoominAreYou from './routes/which-moomin-are-you';
import character from './routes/character';
import characters from './routes/explore';
import moominvalley from './routes/explore';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  common,
  home,

  /** Character page */
  character,

  /** Explore pages */
  characters,
  moominvalley,

  /** Which Moomin are you */
  whichMoominAreYou,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());

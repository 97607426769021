import Macy from 'macy';
import GLightbox from 'glightbox';

export const ImageGallery = () => {

  const macyGalleries = document.querySelectorAll('.image-gallery .gallery');
  const macyInstances = [];
  const macyOptions = {
    trueOrder: false,
    columns: 3,
    breakAt: {
      940: 3,
      767: 2,
      320: 1
    }
  };

  for (let i = 0; i < macyGalleries.length; i++) {
    const newId = 'galleryinstance-' + i;
    macyGalleries[i].id = newId;
    macyOptions.container = '#' + newId;
    macyInstances.push(Macy(macyOptions));
  }

  /**
   * Inserts title and description to gallery from image captions
   * Captions need to be in format
   * title: something something; description something something
   */
  $('.gallery .gallery-item').each(function(){
    const caption = $(this).find('figcaption').text();
    $(this).find('a').attr('data-glightbox', caption.trim());
  });

  const myGallery = GLightbox({
    selector: '.gallery a',
    autoplayVideos: true,
  });

};
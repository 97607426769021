export const quiz = ($) => {
  var WhichMoomin = (function() {
    let _characters = undefined;
    let characters = undefined;
    let questionNo = undefined;
    let characterIndex = undefined;
    let pickedWinner = undefined;
    let countScores = undefined;
    let pickWinner = undefined;
    let copyObject = undefined;
    let showProductCollection = undefined;
    let collectionsContainer = $('[data-quiz=collection]');

    WhichMoomin = class WhichMoomin {
      static initClass() {
    
        _characters = {"moomintroll":0, "snorkmaiden":0, "moominpappa":0, "moominmamma":0, "little-my":0, "snufkin":0, "sniff":0, "stinky":0, "hattifatteners":0, "groke":0, "mymble":0, "too-ticky":0, "hemulen":0, "fillyjonk":0, "thingummy-and-bob":0};
        characters = _characters;
    
        questionNo = 1;
        characterIndex = 0;
        pickedWinner = '';
    
        countScores = function() {
          const arr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    
          return $('.answer-radio').each(function(i, elem) {
            if ($(elem).attr('checked') === 'checked') {
              const related_to = $(elem).data('characters').split(',');
              i = 0;
              while (i < related_to.length) {
                characters[related_to[i]] = characters[related_to[i]] + 1;
                i++;
              }
            }
          });
        };
    
        pickWinner = function() {
          let biggestVal = 0;
          let biggestKey = '';
          let j = 0;
          $.each(Object(characters), function(key, val) {
            if (biggestVal < val) {
              biggestKey = key;
              biggestVal = val;
              characterIndex = j;
            }
            return j++;
          });
          return biggestKey;
        };

        showProductCollection = (character) => {
          if ($(`[data-quiz-character=${character}]`).length < 1) {
            return;
          }
          $(`[data-quiz-character=${character}]`).addClass('flex-show');
          $(`[data-quiz-character=${character}]`).closest('[data-quiz]').show();
        };

        $('.start-button').click(function() {
          // Smooth scroll to top
          // $('html').velocity({
          //   scrollTop: "50px"
          // }, {
          //     duration: 500
          // });
        //  .velocity('scroll', {
        //     offset: 50,
        //     mobileHA: false,
        //     delay: 350
        //   }
          // );
    
          // Start with first question page and fade out intro
          $('#q-count')[0].innerHTML = questionNo + '/' + $('.question').length;
          $('#q-count').velocity({opacity: 1});

          return $('#q0').velocity({opacity: 0}, {
            duration: 250,
            complete() {
              return $('#q1').velocity({opacity: 1}, {
                begin() {
                  $('#q0').hide();
                  return $('#q1').show();
                }
              }
              );
            }
          }
          );
        });
    
        // Click answer button
        $('.answer-radio').click(function() {
          $(this).attr('checked', true);
          $(this).parent().parent().find('.answer-radio').attr('disabled', true);
          if (questionNo < $('.question').length) {
            return $(`#q${questionNo}`).velocity({opacity: 0}, {
              duration: 250,
              complete() {
                questionNo++;
                return $(`#q${questionNo}`).velocity({opacity: 1}, {
                  begin() {
                    $(`#q${questionNo-1}`).hide();
                    $(`#q${questionNo}`).show();
                    return $('#q-count')[0].innerHTML = questionNo + '/' + $('.question').length;
                  }
                }
                );
              }
            }
            );
    
          } else {
            // $('html').velocity('scroll', {
            //   offset: 0,
            //   mobileHA: false,
            //   delay: 350
            // }
            // );
            $('#q-count').fadeOut();
            return $('#quiz-form').fadeOut({
              complete() {
                return $('#shares').velocity({opacity: 1}, {
                  duration: 250,
                  begin() {
                    $('#shares').show();
                    $(`#q${questionNo}`).hide();
    
                    countScores();
                    console.log(characters);
                    characters = copyObject(_characters); // Reset characters to 0
    
                    pickedWinner = pickWinner();

                    // Saves contents of window.quiz to variable
                    const quiz = window.quiz;
                  
                    $(`#${pickedWinner}`).show(); // Winner image

                    showProductCollection(pickedWinner);

                    $('#result')[0].innerHTML = quiz.characterDescriptions[pickedWinner]; // Winner text
    
                    const characterTranslated = quiz.characterNames[pickedWinner];
                    $('#which-moomin-heading')[0].innerHTML = quiz.you_are + ' ' + characterTranslated + "!";
    
                    $('#share-facebook').on('click', function(){
                      const title = quiz.pre_title.replace(/\+/g, ' ');
                      FB.ui({
                        method: 'share',
                        href: 'https://moomin.com/en/which-moomin-are-you/',
                        quote: `${title} ${characterTranslated}`
                      }, function(response){});
                    });
                    // $('#share-facebook').attr('href', (quiz.fb_uri + 'description=' + quiz.desc + '&name=' + quiz.pre_title + characterTranslated + quiz.post_title + '&picture=' + quiz.social_img_path+pickedWinner+'.png' + '&redirect_uri=' + quiz.site_uri + '&caption=Moomin.com&link='+quiz.site_uri));

                    $('#share-twitter').attr('href', (quiz.twitter_uri + 'text=' + quiz.pre_title + characterTranslated + quiz.post_title + ' @MoominOfficial' +'&url=' + quiz.site_uri + '&referer_url=' + quiz.site_uri + ''));
                    $('#share-pinterest').attr('href', (quiz.pinterest_uri + 'description=' + quiz.pre_title + characterTranslated + quiz.post_title + '%20' +quiz.desc_short + '&media=' + quiz.characterImages[pickedWinner] + '&url=' + quiz.site_uri));
                    console.log(pickedWinner);

                    // GTM PUSH
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      'event': 'whichMoominCharacter',
                      'character': pickedWinner,
                    });
                      
                    // Setup Shopify features
                    return $.each($('.featured-products').find('.featured-item'), function(i, elem) {
                      const $elem = $(elem);
                      const shopifyObject = quiz[`shopify_${pickedWinner}`][i];
    
                      const prices = shopifyObject.variants[0].price.split('.');
                      let image = shopifyObject.image.src.replace(/(\?v\=)(\d+)/g, '');
                      image = image.replace(/\.(?=[^.]*$)/, "_medium.");
    
                      $elem.closest('a').attr('href', `https://shop.moomin.com/products/${shopifyObject.handle}`);
                      $elem.find('img').attr('src', image);
                      $elem.find('.manufacturer')[0].innerHTML = shopifyObject.vendor;
                      $elem.find('h6')[0].innerHTML = shopifyObject.title;
                      $elem.find('.price-tag-integer')[0].innerHTML = prices[0];
                      return $elem.find('.price-tag-decimal')[0].innerHTML = prices[1];
                  });
                  }
                });
              }
            });
          }});
    
        $('#quiz-form').submit(() => false);
    
        // Copy JSON object data, not the variable reference like is the case in x = y
        copyObject = orig => JSON.parse(JSON.stringify(orig));
      }
    };
    WhichMoomin.initClass();
    return WhichMoomin;
  })();
  
  WhichMoomin = new WhichMoomin();
};

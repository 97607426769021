import LazyLoad from 'vanilla-lazyload';
import fitvids from 'fitvids';
import { ImageGallery } from '../util/ImageGallery';
import SearchPopup from '../util/SearchPopup';
import LanguagePopup from '../util/LanguagePopup';
import { UI } from '../util/UI';
import { Navigation } from '../util/Navigation';
import { LoadMoreArticles } from '../util/Posts';

export default {
  init() {
    // JavaScript to be fired on all pages
    const myLazyLoad = new LazyLoad({
      elements_selector: ".lazy, .card-product .ar__content img"
    });

    LoadMoreArticles($, myLazyLoad);

    fitvids('.rte', { players: ['iframe[src*="facebook.com"]'] });

    window.fbAsyncInit = function() {
      FB.init({
        appId      : '463703117134373',
        xfbml      : true,
        version    : 'v2.6'
      });
    };
    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
       
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    ImageGallery();
    SearchPopup();
    LanguagePopup();
    UI();
    Navigation();
  },
};

import 'endless-river/endless-river';

export default {
  init() {
    
    $("#character-marquee").endlessRiver();
    
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};

var $ = require('jquery');
window.jQuery = $;
window.$ = $;
import 'velocity-animate';
import { quiz } from './quiz.js';

export default {
  init() {
    console.log('quiz');
  },
  finalize() {
    quiz($);
  },
};

const img = $('.explore__map__img');

export default {
  init() {

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });

  },
  finalize() {
  },
};

export function hideOnClickOutside(selector) {
  const outsideClickListener = (event) => {
    const $target = $(event.target);
    if (!$target.closest(selector).length && $(selector).is(':visible')) {
        $(selector).removeClass('active');
        removeClickListener();
    }
  }
  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener)
  }
  document.addEventListener('click', outsideClickListener)
}

export const Navigation = () => {

  /**
   * Toggles between full and smaller navigation while scrolling
   */
  const $nav = $('.moomin-nav');
  let lastScrollTop = 0;

  $(window).scroll(function(event){
    const st = $(this).scrollTop();
    if (st > lastScrollTop){
      if (st > 50) {
        $nav.removeClass('show-full');
      }
    } else {
      $nav.addClass('show-full');
    }
    lastScrollTop = st;
  });

  $(document).on('click', '[data-sub-menu=toggle]', function(e){
    e.preventDefault();
    const $link = $(this).parent('a');
    const $dropdown = $link.siblings('[data-sub-menu=dropdown]');
    if ($dropdown.is(':visible')) {
      $link.removeClass('open');
      $link.addClass('closed');
      $dropdown.hide();
    } else {
      $link.addClass('open');
      $link.removeClass('closed');
      $dropdown.show();
    }
  });

  $(document).on('click', '[data-search-toggle]', function(e){
    e.preventDefault();
    if ($(this).parent().hasClass('active')) {
      $(this).parent().removeClass('active');
    } else {
      $(this).parent().addClass('active');
      $(this).next('form').find('[name=s]').focus();
      hideOnClickOutside('.moomin-nav__search');
    }
  });
};
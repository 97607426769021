export const LoadMoreArticles = ($, myLazyLoad) => {
  const container = $('[data-more-posts=container]');

  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }
    
  const createParams = (el) => {
    const excluded_posts = el.data('more-posts-excluded');
    const paged = el.data('more-posts-page');
    return {
      excluded_posts,
      paged,
    }
  };

  const updatePageUrl = (page) => {
    if (history.pushState) {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + page;
      window.history.pushState(null, null, newurl);
    }
  }

  const fetchPosts = (url, page) => {
    if (!url) {
      return;
    }
    return fetch(url)
      .then(handleErrors)
      .then(response => response.text())
      .catch(error => console.log(error) );
  }

  $(document).on('click', '[data-more-posts=link]', function(e){
    e.preventDefault();

    const $loader = $('[data-more-posts=loader]');
    const page = $(this).data('next-page');
    const lang = $(this).data('lang');
    const cat = $(this).data('cat');
    const tag = $(this).data('tag');
    const url = new URL(`${window.location.protocol}//${window.location.host}/wp-json/moomin/v1/load_more_posts?paged=${page}&lang=${lang}&cat=${cat}&tag=${tag}`);
    
    $loader.addClass('show');
    const $more_posts_container = $(this).closest('.container-fluid');
    $(this).remove();
    
    fetchPosts(url).then(res => {
      $more_posts_container.remove();
      $loader.removeClass('show');
      container.append(res);
      // updatePageUrl(page);
      myLazyLoad.update();
    });
  });
}